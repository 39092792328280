export const constant = {
    //------------ Localhost
    /* baseUrl: 'http://127.0.0.1:5001/',
    newBaseUrl:'http://127.0.0.1:5000', 
    //newBaseUrl:'https://dreapi.videobanalo.com',
    officetypeId:'6724de1fb6397957c6ef545e',
    companyId:'6724de10b6397957c6ef5458',
    industryId:'6724de16b6397957c6ef545b',
    industryScarpingId:'67344c692b95911882bf632b',
    jobtitleScrapingId:"673451e72b95911882bf6521", */
    

    //----------- GTM
    //baseUrl: 'http://127.0.0.1:5002/',
    //baseUrl: 'https://api.videobanalo.com/',
    baseUrl: 'https://drapi.videobanalo.com/',
    newBaseUrl:'https://emailapi.videobanalo.com',
    officetypeId:'671a2c02027ef0524084f756',
    companyId:'671a2bea027ef0524084f74d',
    industryId:'671a2bfb027ef0524084f753', 
    industryScarpingId:'67389f07d8bcaabec974009e',
    jobtitleScrapingId:"67389f13d8bcaabec97400a1", 
    leadsourseId:'6741a36606c70b2cefd7d7ad',
    projectTypeId:'6741a36f06c70b2cefd7d7b0',
    actionRequiredId:'6741a37806c70b2cefd7d7b3',
    defaultContactLocatioId:'67447f6918d6bd4d8c21d381',
    socialMediaId:'6745c52200fcdcba55307dff',
    leadStatusId:'6748b4dabf1ee05b9fdf54ed',
    
 
    //----------- Dr Client
  /*    //baseUrl: 'http://127.0.0.1:5001/',
    baseUrl: 'https://drapi.videobanalo.com/',
    newBaseUrl:'https://dreapi.videobanalo.com',
    officetypeId:'671a2c02027ef0524084f756',
    companyId:'671a2bea027ef0524084f74d',
    industryId:'671a2bfb027ef0524084f753', 
    industryScarpingId:'67389f07d8bcaabec974009e',
    jobtitleScrapingId:"67389f13d8bcaabec97400a1", 
    leadsourseId:'6741a36606c70b2cefd7d7ad',
    projectTypeId:'6741a36f06c70b2cefd7d7b0',
    actionRequiredId:'6741a37806c70b2cefd7d7b3',
    defaultContactLocatioId:'67447f6918d6bd4d8c21d381',//6741a67706c70b2cefd7d930
    socialMediaId:'6745c52200fcdcba55307dff', */
    
   
}

 