
import { Icon } from "@chakra-ui/react";
import { HiUsers } from "react-icons/hi";
import {
  MdContacts,
  MdHome,
  MdInsertChartOutlined,
  MdLeaderboard,
  MdLock,
  MdPayment
} from "react-icons/md";
// icon
import React from "react";
import { AiFillFolderOpen, AiOutlineMail } from "react-icons/ai";
import { FaCalendarAlt, FaRupeeSign, FaTasks, FaWpforms } from "react-icons/fa";
import { LuBuilding2 } from "react-icons/lu";
import { PiPhoneCallBold,PiWhatsappLogoFill,PiLinkedinLogoFill,PiInstagramLogoFill } from "react-icons/pi";
import { FaCreativeCommonsBy } from "react-icons/fa";
import { SiGooglemeet,SiGoogleads } from "react-icons/si";
import { ROLE_PATH } from "./roles";
import ChangeImage from "views/admin/image";
import Validation from "views/admin/validation";
import CustomField from "views/admin/customField";
import TableField from "views/admin/tableField";
import activeDeactiveModule from "views/admin/activeDeactiveModule";
import { TbExchange, TbTableColumn } from "react-icons/tb";
import { GrValidate } from "react-icons/gr";
import { VscFileSubmodule } from "react-icons/vsc";
import { HiTemplate,HiOutlineMail } from "react-icons/hi";
import { TbBulb } from "react-icons/tb";
import { BsBlockquoteRight } from "react-icons/bs";
import { RiAccountCircleFill } from "react-icons/ri";
import { TbFileInvoice } from "react-icons/tb";
// Admin Imports
const MainDashboard = React.lazy(() => import("views/admin/default"));

// My component
const Contact = React.lazy(() => import('views/admin/contact'));
const ContactView = React.lazy(() => import('views/admin/contact/View'));
const ContactImport = React.lazy(() => import("views/admin/contact/components/ContactImport"));

const Quotes = React.lazy(() => import('views/admin/quotes'));
const QuotesView = React.lazy(() => import('views/admin/quotes/View'));
const QuotesImport = React.lazy(() => import("views/admin/quotes/components/QuotesImport"));

const Invoices = React.lazy(() => import('views/admin/invoice'));
const InvoicesView = React.lazy(() => import('views/admin/invoice/View'));
const InvoicesImport = React.lazy(() => import("views/admin/invoice/components/InvoiceImport"));

const User = React.lazy(() => import("views/admin/users"));
const UserView = React.lazy(() => import("views/admin/users/View"));

//const Property = React.lazy(() => import("views/admin/property"));
//const PropertyView = React.lazy(() => import("views/admin/property/View"));
//const PropertyImport = React.lazy(() => import("views/admin/property/components/PropertyImport"))

const Lead = React.lazy(() => import("views/admin/lead"));
const LeadView = React.lazy(() => import("views/admin/lead/View"));
const LeadImport = React.lazy(() => import("views/admin/lead/components/LeadImport"));

//const Communication = React.lazy(() => import("views/admin/communication"));

const Task = React.lazy(() => import("views/admin/task"));
const TaskView = React.lazy(() => import("views/admin/task/components/taskView"));
const Calender = React.lazy(() => import("views/admin/calender"));
const Payments = React.lazy(() => import("views/admin/payments"));
const Role = React.lazy(() => import("views/admin/role"));

const Document = React.lazy(() => import("views/admin/document"));

const EmailHistory = React.lazy(() => import("views/admin/emailHistory"));
const EmailHistoryView = React.lazy(() => import("views/admin/emailHistory/View"));

const Meeting = React.lazy(() => import("views/admin/meeting"));
const MettingView = React.lazy(() => import("views/admin/meeting/View"));

const PhoneCall = React.lazy(() => import("views/admin/phoneCall"));
const PhoneCallView = React.lazy(() => import("views/admin/phoneCall/View"));

const Report = React.lazy(() => import("views/admin/reports"));
const EmailTemplate = React.lazy(() => import("views/admin/emailTemplate"));
const AddEdit = React.lazy(() => import("views/admin/emailTemplate/AddEdit"));
const templateView = React.lazy(() => import("views/admin/emailTemplate/view.js"));

// Auth Imports
const SignInCentered = React.lazy(() => import("views/auth/signIn"));
// admin setting 
const AdminSetting = React.lazy(() => import("views/admin/adminSetting"));
//const validation = React.lazy(() => import("views/admin/validation"));
const module = React.lazy(() => import("views/admin/moduleName"));
const Opportunities = React.lazy(() => import("views/admin/opportunities"));
const OpportunitiesView = React.lazy(() => import("views/admin/opportunities/View"));
const OpportunitiesImport = React.lazy(() => import("views/admin/opportunities/components/OpprtunityImport"));
const Account = React.lazy(() => import("views/admin/account"));
const AccountView = React.lazy(() => import("views/admin/account/View"));
const LocationAdd = React.lazy(() => import("views/admin/account/indexLocation"));
const AccountImport = React.lazy(() => import("views/admin/account/components/AccountImport"));
const locationView = React.lazy(() => import("views/admin/account/locationView"));
//const CompanyProfile = React.lazy(() => import("views/admin/mnh/companyProfile"));
//const DesignationManagement = React.lazy(() => import("views/admin/mnh/designationManagement"));
//const leadsStatus = React.lazy(() => import("views/admin/mnh/leadsStatus"));
//const quotationStatus = React.lazy(() => import("views/admin/mnh/quotationStatus"));
//const teamMembers = React.lazy(() => import("views/admin/mnh/teamMembers"));

const toolsSeting = React.lazy(() => import("views/admin/mnh/toolsSetting"));
const suvbToolsSeting = React.lazy(() => import("views/admin/mnh/toolsSetting/indexSubcat"));
const emailMarketing = React.lazy(() => import("views/admin/emailMarketing/index"));
const underConstructionEmail = React.lazy(() => import("views/admin/emailMarketing/underConstruction"));
const emailScraping = React.lazy(() => import("views/admin/emailMarketing/scrapIndex"));
const emailScrapingStatus = React.lazy(() => import("views/admin/emailMarketing/emailScrapStatus"));
const createCampaign = React.lazy(() => import("views/admin/emailMarketing/createCampaign/index"));
const emailProviders = React.lazy(() => import("views/admin/emailMarketing/emailProviders/index"));
const importCSV = React.lazy(() => import("views/admin/emailMarketing/importsData"));
const sheduleCampaign = React.lazy(() => import("views/admin/emailMarketing/sheduleCampaign/index"));

const whatsappMarketing = React.lazy(() => import("views/admin/whatsappMarketing/index"));
const linkedinMarketing = React.lazy(() => import("views/admin/linkedinMarketing/index"));
const instagramMarketing = React.lazy(() => import("views/admin/instagramMarketing/index"));

const leadManagement = React.lazy(() => import("views/admin/leadManagement/index"));
const underConstruction = React.lazy(() => import("views/admin/leadManagement/underConstruction"));
const marketingModule = React.lazy(() => import("views/admin/marketingModule/index"));
const marketingUnderConstruction = React.lazy(() => import("views/admin/marketingModule/underConstruction"));
const paymentModule = React.lazy(() => import("views/admin/paymentModule/index"));
const paymentUnderConstruction = React.lazy(() => import("views/admin/paymentModule/underConstruction"));
const deleveryModule = React.lazy(() => import("views/admin/deleveryModule/index"));
const deleveryUnderConstruction = React.lazy(() => import("views/admin/deleveryModule/underConstruction"));



const routes = [
  // ========================== Dashboard ==========================
  {
    name: "Dashboard",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/default",
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    component: MainDashboard,
  },

 
 
  // ========================== Admin Layout ==========================
  //-------------------------- Email Marketing
  {
    name: "Email Marketing",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/email-marketing",
    icon: <Icon as={HiOutlineMail} width='20px' height='20px' color='inherit' />,
  
    component: emailMarketing,
  },
  {
    name: "Email Scraping",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/email-scraping",
    under: "emailMarketing",
    icon: <Icon as={GrValidate} width='20px' height='20px' color='inherit' />,
    component: emailScraping,
  },
  {
    name: "Scraping Status",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/scraping-status",
    under: "emailMarketing",
    icon: <Icon as={GrValidate} width='20px' height='20px' color='inherit' />,
    component: emailScrapingStatus,
  },
  {
    name: "Import CSV",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/import-csv",
    under: "emailScraping",
    icon: <Icon as={GrValidate} width='20px' height='20px' color='inherit' />,
    component: importCSV,
  },
  {
    name: "Create Campaign",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/create-campaign",
    under: "emailScraping",
    icon: <Icon as={GrValidate} width='20px' height='20px' color='inherit' />,
    component: createCampaign,
  },
  {
    name: "Email Providers",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/email-providers",
    under: "emailScraping",
    icon: <Icon as={GrValidate} width='20px' height='20px' color='inherit' />,
    component: emailProviders,
  },
  {
    name: "Shedule Campaign",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/shedule-campaign",
    under: "emailScraping",
    icon: <Icon as={GrValidate} width='20px' height='20px' color='inherit' />,
    component: sheduleCampaign,
  },
  
  {
    name: "Under Construction",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/under-construction-email",
    under: "emailScraping",
    icon: <Icon as={GrValidate} width='20px' height='20px' color='inherit' />,
    component: underConstructionEmail,
  },
//-------------------------- Whatsapp Marketing
{
  name: "Whatsapp Marketing",
  layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  path: "/whatsapp-marketing",
  icon: <Icon as={PiWhatsappLogoFill} width='20px' height='20px' color='inherit' />,

  component: whatsappMarketing,
},
//-------------------------- Linkedin Marketing
/* {
  name: "Linkedin Marketing",
  layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  path: "/linkedin-marketing",
  icon: <Icon as={PiLinkedinLogoFill} width='20px' height='20px' color='inherit' />,

  component: linkedinMarketing,
}, */
//-------------------------- Instagram Marketing
{
  name: "Social Media Management",
  layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  path: "/social-media-management",
  icon: <Icon as={PiInstagramLogoFill} width='20px' height='20px' color='inherit' />,

  component: instagramMarketing,
},
//-------------------------- Lead Management
{
  name: "Lead Management",
  layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  path: "/lead-management",
  icon: <Icon as={SiGoogleads} width='20px' height='20px' color='inherit' />,

  component: leadManagement,
},

{
  name: "Under Construction",
  layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  path: "/under-construction",
  under: "lead-management",
  parentName: "Lead Management",
  icon: <Icon as={MdLeaderboard} width='20px' height='20px' color='inherit' />,
  component: underConstruction,
},
// ------------- lead Routes ------------------------
{
  name: "Leads",
  layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  path: "/lead",
  under: "lead-management",
  parentName: "Lead Management",
  icon: <Icon as={MdLeaderboard} width='20px' height='20px' color='inherit' />,
  component: Lead,
},
{
  name: "Leads",
  layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  under: "lead",
  parentName: "Leads",
  path: "/leadView/:id",
  component: LeadView,
},
{
  name: "Lead Import",
  layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  under: "lead",
  parentName: "Leads",
  path: "/leadImport",
  component: LeadImport,
},
 // --------------- contact Routes --------------------
 {
  name: "Contacts",
  layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  path: "/contacts",
  under: "lead-management",
  parentName: "Lead Management",
  icon: <Icon as={MdContacts} width='20px' height='20px' color='inherit' />,
  component: Contact,
},
{
  name: "Contacts",
  layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  under: "contacts",
  parentName: "Contacts",
  path: "/contactView/:id",
  component: ContactView,
},
{
  name: "Contact Import",
  layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  both: true,
  under: "contacts",
  parentName: "Contacts",
  path: "/contactImport",
  component: ContactImport,
},

 // ------------- Phone Routes ------------------------
 {
  name: "Calls",
  layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  path: "/phone-call",
  under: "lead-management",
  parentName: "Lead Management",
  icon: <Icon as={PiPhoneCallBold} width='20px' height='20px' color='inherit' />,
  component: PhoneCall,
},
{
  name: "Calls",
  layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  under: "phone-call",
  parentName: "Calls",
  path: "/phone-call/:id",
  component: PhoneCallView,
},
// ------------- Email Routes------------------------
{
  // separator: 'History',
  name: "Emails",
  layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  path: "/email",
  under: "lead-management",
  parentName: "Lead Management",
  icon: <Icon as={AiOutlineMail} width='20px' height='20px' color='inherit' />,
  component: EmailHistory,
},
{
  name: "Emails ",
  layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  under: "Emails",
  parentName: "Emails",
  path: "/Email/:id",
  component: EmailHistoryView,
},
// -----------------------------Email Template-------------------------------------
{
  name: "Email Template",
  layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  path: "/email-template",
  under: "lead-management",
  parentName: "Lead Management",
  icon: <Icon as={HiTemplate} width='20px' height='20px' color='inherit' />,
  component: EmailTemplate,
},
{
  name: "Add Email Template",
  layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  under: "email-template",
  parentName: "Email Template",
  path: "/email-template/email-template-addEdit",
  icon: <Icon as={HiTemplate} width='20px' height='20px' color='inherit' />,
  component: AddEdit,
},
{
  name: "Email Template",
  layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  under: "email-template",
  parentName: "Email Template",
  path: "/email-template/:id",
  icon: <Icon as={HiTemplate} width='20px' height='20px' color='inherit' />,
  component: templateView,
},

  //----------------------- Tool Setting
  {
    name: "Tool Setting",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/tool-setting",
    under: "toolsSeting",
    icon: <Icon as={GrValidate} width='20px' height='20px' color='inherit' />,
    component: toolsSeting,
  },
  
  {
    name: "Tool Setting",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/tool-setting/:cId",
    under: "toolsSeting",
    parentName: "Tool Setting",
    icon: <Icon as={RiAccountCircleFill} width='20px' height='20px' color='inherit' />,
    component: suvbToolsSeting,
  },
    
//-------------------------- Marketing Module
{
  name: "Marketing Module",
  layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  path: "/marketing-module",
  icon: <Icon as={MdLeaderboard} width='20px' height='20px' color='inherit' />,

  component: marketingModule,
},
{
  name: "Under Construction",
  layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  path: "/under-construction-marketing",
  under: "marketing-module",
  parentName: "Marketing Module",
  icon: <Icon as={MdLeaderboard} width='20px' height='20px' color='inherit' />,
  component: marketingUnderConstruction,
},
// -----------------------------Opportunities-------------------------------------
{
  name: "Opportunities",
  layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  path: "/opportunities",
  under: "marketing-module",
  parentName: "Marketing Module",
  icon: <Icon as={TbBulb} width='20px' height='20px' color='inherit' />,
  component: Opportunities,
},
{
  name: "Opportunities",
  layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  path: "/opportunitiesView/:id",
  under: "opportunities",
  parentName: "Opportunities",
  icon: <Icon as={TbBulb} width='20px' height='20px' color='inherit' />,
  component: OpportunitiesView,
},
{
  name: "Opportunities",
  layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  path: "/opprotunitiesImport",
  under: "opportunities",
  parentName: "Opportunities",
  icon: <Icon as={TbBulb} width='20px' height='20px' color='inherit' />,
  component: OpportunitiesImport,
},

// --------------- Quotes Routes --------------------
{
  name: "Quotes",
  layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  path: "/quotes",
  under: "marketing-module",
  parentName: "Marketing Module",
  icon: <Icon as={BsBlockquoteRight} width='20px' height='20px' color='inherit' />,
  component: Quotes,
},
{
  name: "Quotes",
  layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  under: "quotes",
  parentName: "Quotes",
  path: "/quotesView/:id",
  component: QuotesView,
},
{
  name: "Quotes Import",
  layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  both: true,
  under: "quotes",
  parentName: "Quotes",
  path: "/quotesImport",
  component: QuotesImport,
},
 // ------------- Calender Routes ------------------------
 {
  name: "Calender",
  layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  path: "/calender",
  under: "marketing-module",
  parentName: "Marketing Module",
  icon: <Icon as={FaCalendarAlt} width='20px' height='20px' color='inherit' />,
  component: Calender,
},
 // ------------- Meeting Routes ------------------------
 {
  name: "Meetings",
  layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  path: "/metting",
  under: "marketing-module",
  parentName: "Marketing Module",
  icon: <Icon as={SiGooglemeet} width='20px' height='20px' color='inherit' />,
  component: Meeting,
},
{
  name: "Meetings ",
  layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  under: "Meetings",
  parentName: "Meetings",
  path: "/metting/:id",
  component: MettingView,
},
//-------------------------- Payment Module
/* {
  name: "Payment Module",
  layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  path: "/payment-module",
  icon: <Icon as={MdPayment} width='20px' height='20px' color='inherit' />,
  component: paymentModule,
},
{
  name: "Under Construction",
  layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  path: "/under-construction-payment",
  under: "payment-module",
  parentName: "Payment Module",
  icon: <Icon as={MdPayment} width='20px' height='20px' color='inherit' />,
  component: paymentUnderConstruction,
}, */

// --------------- Invoices Routes --------------------
{

  name: "Invoices",
  layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  path: "/invoices",
  icon: <Icon as={TbFileInvoice} width='20px' height='20px' color='inherit' />,
  component: Invoices,
},
{
  name: "Invoices",
  layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  under: "invoices",
  parentName: "Invoices",
  path: "/invoicesView/:id",
  component: InvoicesView,
},
{
  name: "Invoices Import",
  layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  both: true,
  under: "invoices",
  parentName: "Invoices",
  path: "/invoicesImport",
  component: InvoicesImport,
},
/* 
{

  name: "Invoices",
  layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  path: "/invoices",
  under: "payment-module",
  parentName: "Payment Module",
  icon: <Icon as={TbFileInvoice} width='20px' height='20px' color='inherit' />,
  component: Invoices,
},
{
  name: "Invoices",
  layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  under: "invoices",
  parentName: "Invoices",
  path: "/invoicesView/:id",
  component: InvoicesView,
},
{
  name: "Invoices Import",
  layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  both: true,
  under: "invoices",
  parentName: "Invoices",
  path: "/invoicesImport",
  component: InvoicesImport,
},

  // ------------- Payments Routes ------------------------
  {
    name: "Payments",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/payments",
    under: "payment-module",
    parentName: "Payment Module",
    icon: <Icon as={FaRupeeSign} width='20px' height='20px' color='inherit' />,
    component: Payments,
  },
 */
//-------------------------- Delevery Module
{
  name: "Delevery Module",
  layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  path: "/delevery-module",
  icon: <Icon as={FaTasks} width='20px' height='20px' color='inherit' />,

  component: deleveryModule,
},
{
  name: "Under Construction",
  layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  path: "/under-construction-delevery",
  under: "delevery-module",
  parentName: "Delevery Module",
  icon: <Icon as={MdLeaderboard} width='20px' height='20px' color='inherit' />,
  component: deleveryUnderConstruction,
},
// ------------- Task Routes ------------------------
{
  name: "Tasks",
  layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  path: "/task",
  under: "delevery-module",
  parentName: "Delevery Module",
  icon: <Icon as={FaTasks} width='20px' height='20px' color='inherit' />,
  component: Task,
},
{
  name: "Tasks",
  layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  under: "task",
  parentName: "Tasks",
  path: "/view/:id",
  component: TaskView,
},
// -----------------------------Companies/Account-------------------------------------

{
  name: "Account",
  layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  path: "/account",
  under: "admin",
  icon: <Icon as={LuBuilding2} width='20px' height='20px' color='inherit' />,
  component: Account,
},
{
  name: "Account",
  layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  path: "/accountView/:id",
  under: "account",
  parentName: "Account",
  icon: <Icon as={RiAccountCircleFill} width='20px' height='20px' color='inherit' />,
  component: AccountView,
},

{
  name: "Account",
  layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  path: "/accountImport",
  under: "account",
  parentName: "Account",
  icon: <Icon as={RiAccountCircleFill} width='20px' height='20px' color='inherit' />,
  component: AccountImport,
},

{
  name: "Location",
  layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  path: "/account/location/:cId",
  under: "account",
  parentName: "Account",
  icon: <Icon as={RiAccountCircleFill} width='20px' height='20px' color='inherit' />,
  component: LocationAdd,
},
{
  name: "Company Location View",
  layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  path: "/account/location/view/:id",
  under: "account",
  parentName: "Account",
  icon: <Icon as={RiAccountCircleFill} width='20px' height='20px' color='inherit' />,
  component: locationView,
},

  // -----------------------------Admin setting-------------------------------------
  {
    name: "Admin Setting",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    // parentName: "admin",
    under: "admin",
    path: "/admin-setting",
    component: AdminSetting,
  },
  {
    name: "Roles",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/role",
    under: "role",
    icon: <Icon as={FaCreativeCommonsBy} width='20px' height='20px' color='inherit' />,
    component: Role,
  },
  {
    name: "Custom Fields",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/custom-Fields",
    under: "customField",
    icon: <Icon as={FaWpforms} width='20px' height='20px' color='inherit' />,
    component: CustomField,
  },
  {
    name: "Change Images",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/change-images",
    under: "image",
    icon: <Icon as={TbExchange} width='20px' height='20px' color='inherit' />,
    component: ChangeImage,
  },
  {
    name: "Validation",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/validations",
    under: "Validation",
    icon: <Icon as={GrValidate} width='20px' height='20px' color='inherit' />,
    component: Validation,
  },
  {
    name: "Table Fields",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/table-field",
    under: "tableField",
    icon: <Icon as={TbTableColumn} width='20px' height='20px' color='inherit' />,
    component: TableField,
  },
  {
    name: "Active Deactive Module",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/active-deactive-module",
    under: "activeDeactiveModule",
    icon: <Icon as={TbTableColumn} width='20px' height='20px' color='inherit' />,
    component: activeDeactiveModule,
  },
  {
    name: "Module",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/module",
    under: "module",
    icon: <Icon as={VscFileSubmodule} width='20px' height='20px' color='inherit' />,
    component: module,
  },
  // ------------- Document Routes ------------------------
  {
    name: "Documents",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/documents",
    icon: <Icon as={AiFillFolderOpen} width='20px' height='20px' color='inherit' />,
    component: Document,
  },
  // ----------------- Reporting Layout -----------------
 /*  {
    name: "Reporting and Analytics",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/reporting-analytics",
    icon: <Icon as={MdInsertChartOutlined} width='20px' height='20px' color='inherit' />,
    component: Report,
  }, */
 
  // ------------- user Routes ------------------------
  {
    name: "Users",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/user",
    under: "user",
    icon: <Icon as={HiUsers} width='20px' height='20px' color='inherit' />,
    component: User,
  },
  {
    name: "User View",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    parentName: "Email",
    under: "user",
    path: "/userView/:id",
    component: UserView,
  },
  // ========================== User layout ==========================

  // ========================== auth layout ==========================
  {
    name: "Sign In",
    layout: "/auth",
    path: "/sign-in",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: SignInCentered,
  },
  // ------------- Property Routes ------------------------
  /* {
    name: "Properties",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/properties",
    icon: <Icon as={LuBuilding2} width='20px' height='20px' color='inherit' />,
    component: Property,
  },
  {
    name: "Properties",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    parentName: "Properties",
    under: "properties",
    path: "/propertyView/:id",
    component: PropertyView,
  },
  {
    name: "Property Import",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    both: true,
    under: "properties",
    parentName: "Properties",
    path: "/propertyImport",
    component: PropertyImport,
  }, */

  
  

  // // ------------- Communication Integration Routes ------------------------
  // {
  //   name: "Communication Integration",
  //   layout: [ROLE_PATH.admin, ROLE_PATH.user],

  //   path: "/communication-integration",
  //   icon: <Icon as={GiSatelliteCommunication} width='20px' height='20px' color='inherit' />,
  //   component: Communication,
  // },
];

export default routes;
